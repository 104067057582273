<template>
    <div class="page__wrapper">
        <div class="page__inner">
            <h1>{{ $t('navbar.eu') }}</h1>
            <div class="content">
                <h2 class="mt-32">{{ $t('EUSupport.title') }}</h2>
                <p class="mt-24">{{ $t('EUSupport.paragraph1') }}</p>
                <p class="mt-24">{{ $t('EUSupport.paragraph2') }}</p>
                <p class="mt-32">{{ $t('EUSupport.paragraph3') }}</p>
                <div class="footer-item mt-32">
                    <h5 class="mr-5">{{ $t('EUSupport.projectTitle') }}</h5>
                    <p>{{ $t('EUSupport.projectTitleText') }}</p>
                </div>
                <div class="footer-item mt-10">
                    <h5 class="mr-5">{{ $t('EUSupport.projectNumber') }}</h5>
                    <p>{{ $t('EUSupport.projectNumberText') }}</p>
                </div>
                <div class="footer-item mt-10">
                    <h5 class="mr-5">{{ $t('EUSupport.projectStarts') }}</h5>
                    <p>{{ $t('EUSupport.projectStartsText') }}</p>
                </div>
                <div class="footer-item mt-10">
                    <h5 class="mr-5">{{ $t('EUSupport.projectEnds') }}</h5>
                    <p>{{ $t('EUSupport.projectEndsText') }}</p>
                </div>
                <div class="footer-item mt-10">
                    <h5 class="mr-5">{{ $t('EUSupport.projectValue') }}</h5>
                    <p>{{ $t('EUSupport.projectValueText') }}</p>
                </div>
            </div>
            <img src="@/assets/images/es_parama.jpg" alt="es_parama" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EUSupport',
    };
</script>

<style scoped lang="scss">
    .page__wrapper {
        @include column-align-stretch;

        padding-top: 25px;
        padding-bottom: 25px;

        .page__inner {
            width: 100%;

            .footer-item {
                @include row-align-center;
            }
        }
    }

    @include media($lg) {
        .page__wrapper {
            @include row-align-start;

            padding-top: 50px;
            padding-bottom: 0;

            .page__inner {
                max-width: 600px;

                margin: 0 auto;
            }
        }
    }

    @include media($xl) {
        .page__wrapper {
            .page__inner {
                max-width: 800px;
            }
        }
    }
</style>
